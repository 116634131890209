import React from "react";

import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import CropFreeIcon from "@mui/icons-material/CropFree";
import TimelineIcon from "@mui/icons-material/Timeline";
import TollIcon from "@mui/icons-material/Toll";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";

import ProjectPage from "../../components/project-page";
import ThumbnailImage from "../../components/thumbnail";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  query SoftwareRasterizerQuery {
    image1: file(relativePath: { eq: "projects/rasterizer/rasterizer1.png" }) {
      ...projectThumbnailImage
    }

    image2: file(relativePath: { eq: "projects/rasterizer/rasterizer2.png" }) {
      ...projectThumbnailImage
    }
  }
`;

const SoftwareRasterizerPage: React.FC = () => {
  const projectName = `Software Rasterizer`;
  const data = useStaticQuery(query);

  return (
    <ProjectPage projectName={projectName}>
      <Typography variant="body1" component="p">
        This was a small 3D software rasterizer project developed during a
        semester at DigiPen without using any other 3D API&apos;s. This project
        does not use hardware rasterization. Everything that is drawn to the
        screen uses a set pixel function in software mode.
      </Typography>
      <Typography variant="h5" component="h2">
        Algorithms Used
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <CropFreeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Clipping"
            secondary="Lines and surfaces outside the view frustum are removed"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <VisibilityOffIcon />
          </ListItemIcon>
          <ListItemText
            primary="Culling"
            secondary="Determines whether a polygon is visible and if it should be drawn"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <TimelineIcon />
          </ListItemIcon>
          <ListItemText
            primary="Bilinear Interpolation"
            secondary="Linear interpolation with two variables (x, y)"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SaveAltIcon />
          </ListItemIcon>
          <ListItemText primary="Hyperbolic Interpolation (Floor texture)" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <WbIncandescentIcon />
          </ListItemIcon>
          <ListItemText primary="Lighting" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <TollIcon />
          </ListItemIcon>
          <ListItemText primary="Shadows" />
        </ListItem>
      </List>
      <Typography variant="h5" component="h2">
        Screenshots
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ThumbnailImage file={data.image1} alt="Screenshot 1" />
        </Grid>
        <Grid item xs={6}>
          <ThumbnailImage file={data.image2} alt="Screenshot 2" />
        </Grid>
      </Grid>
    </ProjectPage>
  );
};

export default SoftwareRasterizerPage;
